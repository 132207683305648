import React from 'react'
import { useTranslation } from 'next-i18next'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common']))
  }
})

const error404 = (): JSX.Element => {
  const { t } = useTranslation('common')

  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
      }}
    >
      <div>
        <h1>{t('404-title')}</h1>
        <p>{t('404-content')}</p>
      </div>
    </div>
  )
}

export default error404
